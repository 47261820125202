<template>
  <b-card
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-form-group
          class="mr-1 mb-md-0"
        >
          <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="gradient-primary"
            block
            @click="addData"
          >
            เพิ่มแอดมิน
          </b-button>
        </b-form-group>
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="visibleFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(picture)="data">
        <b-avatar
          size="lg"
          :src="data.item.picture"
        />
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <a
            :href="`http://${data.item.ip}`"
            target="_blank"
          >{{ data.item.ip }}</a>

        </div>
      </template>
      <template #cell(roleshow)="data">
        <p v-if="data.item.role === 2">
          super admin
        </p>
        <p v-if="data.item.role === 3">
          super admin
        </p>
        <p v-if="data.item.role === null">
          admin
        </p>
      </template>

      <template #cell(time)="data">
        <p v-if="data.item.admintime">
          {{ data.item.admintime }} น.
        </p>
        <p v-else>
          ยังไม่ได้ตั้งเวลาทำงาน
        </p>
      </template>

      <template #cell(status)="data">
        <span
          v-if="data.item.online === 0"
          class="badge badge-glow badge-success"
        > Online </span>

        <span
          v-else
          class="badge badge-glow badge-danger"
        > Offline </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex align-items-center">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
          <i
            v-if="data.item.online === 0 && UserData.vip == 1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Kick Off'"
            class="far fa-power-off mr-1"
            @click="closeItem(data.item.id)"
          />

          <feather-icon
            v-if="UserData.role === 'agadmin' || UserData.vip === 1 || UserData.hide === 1"
            :id="`invoice-row-${data.item.id}-preview-icon`"
            v-b-modal.modal-1
            icon="EditIcon"
            size="16"
            class="mr-1"
            @click="editData(data.item)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            @click="deleteData(data.item.id)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions2)="data">
        <div class="text-nowrap d-flex align-items-center">
          <feather-icon
            v-if="UserData.role === 'agadmin'"
            :id="`invoice-row-${data.item.id}-preview-icon`"
            v-b-modal.modal-1
            icon="EditIcon"
            size="16"
            class="mr-1"
            @click="editData(data.item)"
          />
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      id="modal-1"
      title="แอดมิน"
      ok-title="Submit"
      @ok="submit"
    >
      <b-form-group
        label="ชื่อ"
        label-for="vi-first-name"
      >
        <b-form-input
          id="vi-first-name"
          v-model="name"
          placeholder=""
        />

      </b-form-group>
      <b-form-group
        label="เบอร์โทร"
        label-for="vi-first-name"
      >

        <b-form-input
          id="vi-first-name"
          v-model="tel"
          placeholder=""
        />

      </b-form-group>
      <b-form-group
        label="username"
        label-for="vi-first-name"
      >

        <b-form-input
          id="vi-first-name"
          v-model="username"
          placeholder=""
        />

      </b-form-group>
      <b-form-group
        label="Password"
        label-for="vi-first-name"
      >

        <b-form-input
          id="vi-first-name"
          v-model="password"
          placeholder=""
          type="password"
        />

      </b-form-group>
      <b-form-group
        label="Role"
        label-for="vi-first-name"
      >
        <v-select
          v-model="role"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="text"
          :options="rolelist"
          :reduce="text => text.value"
        />
      </b-form-group>
      <!-- {{ starttime }} -->
      <b-form-group
        label="เวลาเริ่มงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="starttime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group>
      <b-form-group
        label="เวลาเริ่มเลิกงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="endtime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, VBToggle, VBTooltip, BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormTimepicker,
    // flatPickr,
    vSelect,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      depositdata: [],
      rolelist: [
        { text: 'super admin', value: 2 },
        { text: 'admin', value: null },
        { text: 'sbo', value: 3 },
        { text: 'theme admin', value: 4 },
      ],
      starttime: '',
      endtime: '',
      role: null,
      adminData: '',
      name: '',
      tel: '',
      username: '',
      password: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', show: true },
        { key: 'username', label: 'username', show: true },
        { key: 'name', label: 'ชื่อแอดมิน', show: true },
        { key: 'tel', label: 'เบอร์โทร', show: true },
        { key: 'roleshow', label: 'เลเวล', show: true },
        { key: 'time', label: 'เวลาทำงาน', show: true },
        { key: 'status', label: 'status', show: true },
        { key: 'actions', label: 'Action', show: true },
        { key: 'actions2', label: 'Action', show: true },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleFields() {
      return this.fields.filter(field => {
        if (field.key === 'actions' && this.UserData.vip !== 1) {
          return false
        }

        if (field.key === 'actions2' && this.UserData.vip === 1 && this.UserData.hide === 1) {
          return false
        }

        return field.show
      })
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getAdmin()
    // console.log(this.UserData)
  },
  methods: {
    closeItem(val) {
      const formData = {
        id: val,
      }
      this.$http
        .post('/CheckOut/UserAdmin', formData)
        .then(response => {
          // console.log(response.data)
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getAdmin() {
      this.$http
        .get('/agent')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    submit() {
      if (this.UserData.role === 'agadmin' && (!this.starttime || !this.endtime)) {
        this.Warnings('กรุณาเลือกเวลาเข้างานและเลิกงาน')
      } else {
        const formData = {
          admintime: `${this.starttime}-${this.endtime}`,
          name: this.name,
          tel: this.tel,
          username: this.username,
          password: this.password,
          role: this.role,
          id: this.adminData.id,
        }
        if (this.adminData.id) {
          this.$http
            .post('/agent/updateV2', formData)
            .then(() => {
              this.getAdmin()
              this.starttime = ''
              this.endtime = ''
              this.name = ''
              this.tel = ''
              this.username = ''
              this.password = ''
              this.role = null
              this.Success('update สำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$http
            .post('/agent/store', formData)
            .then(() => {
              this.getAdmin()
              this.starttime = ''
              this.endtime = ''
              this.name = ''
              this.tel = ''
              this.username = ''
              this.password = ''
              this.role = null
              this.Success('เพิ่มแอดมินสำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/agent/delete/${id}`)
            .then(response => {
              this.getAdmin()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('Cancelled', 'not been deleted !', 'error')
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.starttime = ''
      this.endtime = ''
      this.name = ''
      this.tel = ''
      this.username = ''
      this.password = ''
      this.role = ''
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // console.log(data.admintime)
      if (data.admintime) {
        const time = data.admintime.split('-')
        // console.log(time)
        // eslint-disable-next-line prefer-destructuring
        this.starttime = time ? time[0] : ''
        // eslint-disable-next-line prefer-destructuring
        this.endtime = time ? time[1] : ''
      } else {
        this.starttime = ''
        this.endtime = ''
      }

      this.adminData = data
      this.name = data.name
      this.tel = data.tel
      this.role = data.role
      this.username = data.username
      this.password = ''
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Warnings(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
